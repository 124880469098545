<template>
  <div :class="`${prefixCls}-form`">
    <div :class="`${prefixCls}-form-topic`">
      <div :class="`${prefixCls}-form-topic-cn`">
        用心聆听每一位客户的心声
      </div>
      <div :class="`${prefixCls}-form-topic-en`">
        LISTEN TO THE VOICE OF EVERY CUSTOMER
      </div>
    </div>
    <el-form :class="`${prefixCls}-form-body`" ref="remarkForm" label-width="100px" :model="formData" :rules="formRules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业名称：" prop="companyName">
            <el-input v-model="formData.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号：" prop="mobile">
            <el-input v-model="formData.mobile" type="tel"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="留言板：" prop="messageBoard">
        <el-input v-model="formData.messageBoard" type="textarea" :rows="5"></el-input>
      </el-form-item>
      <el-form-item label="验证码：" :class="`${prefixCls}-form-body-item`" prop="code">
        <div :class="`${prefixCls}-form-body-item-code`">
          <el-input v-model="formData.code"></el-input>
          <img :src="`${codeUrl}?key=${formData.key}`" @click="refreshCode">
        </div>
      </el-form-item>
      <el-form-item style="margin-top: 120px" label-width="0">
        <el-button :class="`${prefixCls}-form-body-submit`" block :loading="loading" @click="submitRemark">
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { randomLenNum } from '../../../utils/util'
import { submitRemark } from '../../../api/partner'
export default {
  name: 'PartenrComponentsForm',
  data () {
    return {
      prefixCls: this.$prefix + '-partenr',
      codeUrl: '/websites-server/rest/auth/captcha',
      formData: {
        companyName: '',
        mobile: '',
        messageBoard: '',
        code: '',
        key: ''
      },
      formRules: {
        companyName: [],
        mobile: [],
        messageBoard: [],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  created () {
    this.refreshCode()
  },
  methods: {
    refreshCode () {
      this.$set(this.formData, 'key', randomLenNum(4, true))
      this.$set(this.formData, 'code', '')
    },
    submitRemark () {
      this.$refs.remarkForm.validate((valid) => {
        if (valid) {
          this.loading = true
          submitRemark(this.formData).then(({ data }) => {
            if (data.code === 200) {
              this.$message({
                type: 'success',
                message: '感谢您的宝贵意见 我们将做得更好!'
              })
              this.$refs.remarkForm.resetFields()
              this.refreshCode()
            }
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -partenr-form;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 100px 0;
    &-topic{
      margin-bottom: 50px;
      &-cn{
        font-size: 32px;
        margin-bottom: 10px;
        font-family: SourceHanSansCN-Light;
      }
      &-en{
        font-size: 22px;
        color: #969696;
      }
    }
    &-body {
      width: $content-width;
      min-height: 600px;
      &-submit{
        background-color: #FF503F;
        color: #f7f7f7;
        width: 50%;
      }
      &-item{
        white-space: nowrap;
        width: 400px;
        &-code{
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          img {
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
