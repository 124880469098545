import request from '../utils/axios/index'

export const BASE_URL = '/websites-server/rest/frontDesk'

// 提交意见
export function submitRemark (data) {
  return request({
    method: 'post',
    url: `${BASE_URL}/addFeedBack`,
    data
  })
}
// 获取合作伙伴
export function getPartner (data) {
  return request({
    method: 'post',
    url: `${BASE_URL}/getPartner`,
    data
  })
}
