<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-list`">
      <div :class="`${prefixCls}-list-item`" v-for="(item, index) in userList" :key="item.id" @click="changeUserActive(index)">
        <div :class="`${prefixCls}-list-item-avatar ${active === item.userId?prefixCls + '-list-item-active' : ''}`">
          <img src="../../assets/image/partner/header.png" :alt="item.type">
        </div>
        <div :class="`${prefixCls}-list-item-info`" v-if="active === index">
          <div :class="`${prefixCls}-list-item-info-name`">
            {{item.companyName}}
          </div>
          <div :class="`${prefixCls}-list-item-info-type`">
            Manager
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'componentsCustomerList',
  props: {
    userList: {
      type: Array,
      default: () => {
        return []
      }
    },
    active: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      prefixCls: this.$prefix + '-components-customer'
    }
  },
  created () {
  },
  methods: {
    changeUserActive (id) {
      this.$emit('change', id)
    }
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -components-customer;
  .#{$prefixCls}{
    &-list{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 40px;
        transition: all .5s ease-in-out;
        cursor: pointer;
        &-avatar{
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background: #fff;
          img {
            width: 100%;
          }
        }
        &-info{
          color: #fff;
          &-name {
            font-size: 20px;
            margin: 10px 0;
          }
          &-type {
            font-size: 16px;
            color: #e0e4e6;
          }
        }
        &-active{
          width: 100px;
          height: 100px;
          margin: 0 10px;
        }
      }
    }
  }
</style>
