<template>
  <div :class="`${prefixCls}-our`">
    <Separate title="我们的合作伙伴" desc="OUR PARTNER"></Separate>
    <div :class="`${prefixCls}-our-list`">
      <div :class="`${prefixCls}-our-list-item`" v-for="item in partenrList" :key="item.id">
        <el-image :src="item.picturePath" :alt="item.title" fit="cover" :class="`${prefixCls}-our-list-item-img`" @click="clickMark(item)" :title="item.url"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
export default {
  name: 'PartenrComponentsOur',
  data () {
    return {
      prefixCls: this.$prefix + '-partenr'
    }
  },
  props: {
    partenrList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    clickMark (item) {
      if (item.url) {
        window.open(item.url, '_blank')
      }
    }
  },
  components: {
    Separate
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -partenr-our;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
    &-list{
      display: grid;
      grid-template-columns: repeat(3, 33%);
      width: $content-width;
      grid-gap: 140px 20px;
      place-items: center center;
      margin: 0 auto;
      &-item{
        width: 60%;
        &-img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
</style>
