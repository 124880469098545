<template>
  <div :class="`${prefixCls}-remark`">
    <div :class="`${prefixCls}-remark-topic`">
      <div :class="`${prefixCls}-remark-topic-cn`">
        客户之声
      </div>
      <div :class="`${prefixCls}-remark-topic-en`">
        CUSTOMER'SVOICE
      </div>
    </div>
    <el-carousel :class="`${prefixCls}-remark-list`" :initial-index="active" :autoplay="false" indicator-position="none" arrow="always" @change="changeCarousel" ref="remarkCarousel" height="300px">
      <el-carousel-item v-for="item in customerVoice" :key="item.id" :class="`${prefixCls}-remark-list-item`">
        <div :class="`${prefixCls}-remark-list-item-text`">
          <div>{{ item.messageBoard }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <UserList :userList="customerVoice" :active="active" @change="setActive"></UserList>
    <!-- <div :class="`${prefixCls}-remark-bg`">
      <img src="../../../assets/image/partner/center-bg.png" alt="bg" >
    </div> -->
  </div>
</template>

<script>
import { remarkList, userList } from '../data'
import UserList from '../../../components/customerList/index.vue'

export default {
  name: 'PartenrComponentsRemark',
  data () {
    return {
      prefixCls: this.$prefix + '-partenr',
      remarkList,
      userList,
      active: 1
    }
  },
  props: {
    customerVoice: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    changeCarousel (newIndex, oldIndex) {
      if (newIndex !== oldIndex) {
        this.active = newIndex
      }
    },
    setActive (val) {
      this.active = val
      this.$refs.remarkCarousel.setActiveItem(val)
    }
  },
  components: {
    UserList
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -partenr-remark;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../../assets/image/partner/center-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px 0;
    position: relative;
    &-topic{
      color: #ffffff;
      &-cn{
        font-size: 30px;
        margin-bottom: 20px;
      }
      &-en{
        font-size: 25px;
      }
    }
    &-list{
      width: $content-width;
      min-width: 800px;
      color: #ffffff;
      &-item{
        display: flex;
        &-text {
          width: 70%;
          margin: 0 auto;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
  ::v-deep .el-carousel__arrow {
    background-color: transparent !important;
    font-size: 30px !important;
  }
</style>
