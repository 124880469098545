<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-header`">
      <div  :class="`${prefixCls}-header-carousel`">
        <Carousel :carouselData="partnerData.swiperList" height="600px">
        </Carousel>
      </div>
      <div :class="`${prefixCls}-header-text`">
        <div :class="`${prefixCls}-header-text-title`">合作伙伴</div>
        <div :class="`${prefixCls}-header-text-en`">PARTNER</div>
        <div :class="`${prefixCls}-header-text-desc`">合作、共创、互赢</div>
        </div>
    </div>
    <OurPartner :partenrList="partnerData.partner"></OurPartner>
    <ClientRemark :customerVoice="partnerData.customerVoice"></ClientRemark>
    <RemarkForm></RemarkForm>
  </div>
</template>

<script>
import Carousel from '../../components/Carousel/index.vue'
import OurPartner from './components/ourPartner.vue'
import ClientRemark from './components/clientRemark.vue'
import RemarkForm from './components/remarkForm.vue'
import { getPartner } from '../../api/partner'
export default {
  name: 'Partner',
  data () {
    return {
      prefixCls: this.$prefix + '-partner',
      partnerData: {}
    }
  },
  created () {
    getPartner().then(res => {
      this.partnerData = res.data.data
    })
  },
  components: {
    Carousel,
    OurPartner,
    ClientRemark,
    RemarkForm
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -partner;
  .#{$prefixCls}{
      width: 100%;
    &-header{
      width: 100%;
      overflow: hidden;
      position: relative;
      color: #fff;
      &-carousel{
        width: 100%;
      }
      &-text{
        position: absolute;
        left: 15%;
        top: 200px;
        &-title{
          font-size: 72px;
        }
        &-en{
          font-size: 28px;
          text-align: left;
          font-weight: 400;
          font-family: SourceHanSansCN-Light;
          margin-bottom: 40px;
        }
        &-desc{
          font-size: 25px;
          text-align: left;
        }
      }
      &-img{
        width: 40%;
        min-width: 500px;
        position: absolute;
        bottom: -80px;
      }
    }
  }
</style>
